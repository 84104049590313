
<template>
  <div>
    <el-form label-width="95px" inline size="mini">
      <!-- <el-form-item label="操作人：">
        <el-input style="width:160px;" placeholder="导出人" v-model="search.sysUserId"></el-input>
      </el-form-item> -->
      <el-form-item label="操作人：">
        <el-select v-model="search.sysUserId" clearable filterable placeholder="导出人">
          <el-option v-for="item in userList" :label="item.sysName" :value="item.sysId">{{item.sysName}}</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="导出时间：">
        <el-date-picker v-model="search.createStartTime" type="date" placeholder="选择开始日期" value-format="yyyy-MM-dd" style="width:180px;"></el-date-picker> 至
        <el-date-picker v-model="search.createEndTime" type="date" placeholder="选择结束日期" value-format="yyyy-MM-dd" style="width:180px;"></el-date-picker>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 240px)">
      <el-table-column align="center" label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="sysUserName" align="center" label="导出人"></el-table-column>
      <el-table-column prop="money" align="center" label="导出金额"></el-table-column>
      <el-table-column prop="quantity" align="center" label="导出人数"></el-table-column>
      <el-table-column prop="type" align="center" label="导出类型">
        <template slot-scope="scope">
          <span>{{scope.row.type=='1'?'t+3导出':'普通导出'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" align="center" label="导出时间"></el-table-column>
      <el-table-column align="center" label="操作" width="130">
        <template slot-scope="scope">
          <a :href="scope.row.file" v-if="scope.row.file" style="margin:0 10px">
            <el-button type="text" @click="exportDetail(scope.row)">查看文件</el-button>
          </a>
        </template>
      </el-table-column>
    </el-table>
    <div style="display:flex;width:100%">
      <!-- <p style="line-height:30px;width:200px">共 : {{totalCount || 0}}条</p> -->
      <el-pagination style="text-align:center;flex:1" layout="total,sizes,prev, pager, next" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize" :current-page="pageNum" :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getExportRecordPage } from '@/api/issueList'
import { getCookies, exportCommon } from '@/utils/utils'
import { baseURL } from '@/utils/http'
import { querySysUserListById } from '@/api/setting';

export default {
  data() {
    return {
      url: baseURL,
      search: {
        createStartTime: '',
        createEndTime: '',
        sysUserId: ''
      },
      userList: [],
      totalCount: 0,
      list: [],
      total: 0,//分页总页数
      pageNum: 1,//分页页数
      pageSize: 10,//每页显示多少
    }
  },
  created() {
    // this.search.submitOrg = getCookies('organizationId') == 1 ? '' : getCookies('organizationId')
    this.getList();
  },
  mounted() {
    this.getUserList()
  },
  methods: {
    exportDetail(item) {
      console.log(导出成功);
      // let param = {}
      exportCommon(item.file, param);
      // let param = Object.assign({ isRepeat: 0, isCompare: 0 }, this.search)
      // param.submitOrg = param.submitOrg || getCookies('organizationId')
      // exportCommon("/wlynIntegralWithdrawal/exportExcel", param);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    //获取系统用户列表接口
    getUserList() {
      let param = {
        isPage: 1,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      };
      querySysUserListById(param)
        .then((response) => {
          if (response.code == 200) {
            console.log(response.data.records);
            // this.total = response.data.total;
            this.userList = response.data.records;

          } else {
            this.$message.error({
              title: '错误',
              message: response.msg || response.error_msg,
              duration: 1500
            });
          }

        })
    },
    getList() {
      let param = {
        isPage: 1,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        // isHide: 1
      };
      Object.assign(param, this.search)
      getExportRecordPage(param)
        .then((response) => {
          if (response.code == 200) {
            this.total = response.data.total;
            this.list = response.data.records;
          }
        })
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/common/scss/base";
// .el-tag-style {
//   width: 60px;
//   text-align: center;
// }
.form-item-box {
  float: left;
  height: 46px;
}
.el-form-item {
  margin: 0;
}

.btn-blue-hover {
  margin: 0 6px;
  color: $hoverRed;
}
.btn-blue-hover:hover {
  color: $hoverYellow;
}

.btn-red-hover {
  margin: 0 6px;
  color: $hoverBlue;
}
.btn-red-hover:hover {
  color: $hoverYellow;
}
</style>